<template>
  <div>
    <v-card-title class="display-1 mb-2">
      {{t('$vuetify.serviceCenter.requestCharger')}}
      <v-spacer/>
      <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
              <v-icon
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              large
              >
                  mdi-progress-question
              </v-icon>
          </template>
          <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.<br>
              Magnam, neque totam quasi ipsum necessitatibus amet <br>
              excepturi ex corporis, aut impedit in ratione animi<br>
              fugit dolorem voluptatum vel recusandae eaque molestias!
          </span>
      </v-tooltip>
    </v-card-title>
    <v-card-subtitle>{{t('$vuetify.serviceCenter.dynamicLoadingTitle')}}</v-card-subtitle>
    <v-card-text>
      <v-radio-group v-model="radioGroupGuestCharging">
        <v-row>
          <v-col cols="6"  lg="6">
            <v-card flat outlined min-height="250px">
              <v-card-text>
                <v-radio :label="t('$vuetify.serviceCenter.dynamicLoadingRadio')"></v-radio>
              </v-card-text>
              <v-card-text align="center">
                <v-img
                  width="50%"
                  lazy-src="/requests/dynamic_loading.svg"
                  src="/requests/dynamic_loading.svg"
                  class="mb-2"
                ></v-img> <br>
                <!-- 450 EUR -->
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6"  lg="6">
            <v-card flat outlined min-height="250px">
              <v-card-text>
                <v-radio :label="t('$vuetify.serviceCenter.noDynamicLoadingRadio')"></v-radio>
              </v-card-text>
              <v-card-text align="center">
                <v-img
                  width="50%"
                  lazy-src="/requests/no_dynamic_loading.svg"
                  src="/requests/no_dynamic_loading.svg"
                  class="mb-2"
                ></v-img>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-radio-group>
    </v-card-text>
    <v-divider></v-divider>
        <v-card-actions class="justify-end">
      <!-- {{t('$vuetify.serviceCenter.totPrice')}} : {{totPrice}} € -->
      <v-spacer />
      <v-btn
          color="primary"
          text
          @click="previousStep()"
      >
          <v-icon>mdi-chevron-left</v-icon>
          {{t('$vuetify.generic.back')}}
      </v-btn>
      <v-btn
          color="success"
          text
          @click="nextStep()"
          :disabled="radioGroupGuestCharging === null"
      >
        {{t('$vuetify.generic.next')}}
      </v-btn>
      <v-btn
        color="red"
        text
        @click="cancel"
      >
        {{t('$vuetify.generic.cancel')}}
      </v-btn>
    </v-card-actions>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      radioGroupGuestCharging: 1
    }
  },
  mounted () {
    this.setPrice(this.radioGroupGuestCharging)
  },
  methods: {
    nextStep () {
      this.setPrice(this.radioGroupGuestCharging)
      if (this.radioGroupGuestCharging === 0) {
        this.$store.commit('requestsState/setDynamicType', '13847')
      } else {
        this.$store.commit('requestsState/setDynamicType', '13848')
      }
      this.$store.commit('requestsState/setStep', 'InvoiceAddress')
    },
    previousStep () {
      this.radioGroupGuestCharging = null
      this.$store.commit('requestsState/removePrice', 'dynamictype')
      this.$store.commit('requestsState/setDynamicType', '')
      this.$store.commit('requestsState/setPreviousStepName')
    },
    cancel () {
      this.$emit('cancel')
    },
    setPrice (val) {
      if (val !== null) {
        const elm = { price: 450, type: 'dynamictype' }
        if (!val) {
          elm.price = 450
          this.$store.commit('requestsState/setPrice', elm)
        } else {
          elm.price = 0
          this.$store.commit('requestsState/setPrice', elm)
        }
      }
    }
  },
  computed: {
    ...mapGetters({ price: 'requestsState/price', totPrice: 'requestsState/totalPrice' })
  },
  watch: {
    radioGroupGuestCharging: function (val) {
      if (val !== null) {
        this.setPrice(val)
      }
    }
  }
}
</script>
